"use client";
import React, { useEffect, useRef, useState } from 'react';
import type { TextAreaProps } from "@nextui-org/react";
import { Spinner, Textarea, Input, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { cn } from "./cn";
import { ID_CONSTANTS, promptInputType } from '../utils/constants';
import { CtyButton } from '../button/CtyButton';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@circuitry-ai/doc-data';
import { SearchableDropdown } from '../utiluicomponents/searchabledropdown';
import { SpeechUtility } from '../utils/speechUtils';


interface CtyPromptInputProps extends TextAreaProps {
  inputHeight?: string;
  enableTranslation?: boolean;
  enableSpeak?: boolean;
  enableImage?: boolean;
  isSpeechMode?: boolean;
  enableSendBtn?: boolean;
  enableSearch?: boolean;
  setIsSpeechMode?: (value: boolean) => void;
  handleSendClick: (userInput: string) => void;
  triggerEveryInputChange?: boolean;
  elementType: promptInputType;
  handleImageUpload?: (key: React.Key, e: any) => void;
}

const CtyPromptInput = React.forwardRef<HTMLTextAreaElement, CtyPromptInputProps>(
  ({ classNames = {}, ...props }, ref) => {

    const { inputHeight, handleSendClick, enableImage = false, enableSendBtn = false, enableTranslation = false, enableSpeak = false, enableSearch = false, isSpeechMode = false, setIsSpeechMode, triggerEveryInputChange = false, elementType, handleImageUpload } = props;
    const { isSocketLoading } = useSelector((state: RootState) => state.chatAdvisorSliceReducer);
    const [inputValue, setInputValue] = useState<string>('');
    const [isListening, setIsListening] = useState<boolean>(false);
    const [selectedKey, setSelectedKey] = useState<string>(SpeechUtility.getLanguage() ?? "en-US");
    const dispatch = useAppDispatch();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const selectedUploadFileType = useRef<React.Key>('ocr');
    const handleSubmit = (e?: React.FormEvent) => {
      e?.preventDefault();
      if (!inputValue?.trim()) return;
      handleSendClick(inputValue);
      setIsSpeechMode?.(false);
      setInputValue('');
    };
    const handleDropdownAction = (key: React.Key) => {
      if (key === 'cameraocr') {
        handleImageUpload?.(key, null);
      } else {
        selectedUploadFileType.current = key;
        fileInputRef.current?.click();
      }


    };

    useEffect(() => {
      if (isSpeechMode && !isListening && inputValue?.trim().length > 0) {
        handleSendClick(inputValue);
        setIsSpeechMode?.(false);
        setInputValue('');
      }
    }, [isListening]);

    const handleDropDown = (key: string) => {
      setSelectedKey(key);
      SpeechUtility.destroyInstance();
      SpeechUtility.setLanguage(key);
    };

    const handleSpeechAction = async () => {
      setIsSpeechMode?.(true);
      (await SpeechUtility.getInstance(dispatch)).sttFromMic(isListening, setIsListening, inputValue, setInputValue);
    };

    const startContent = () => {
      return (
        <div className='relative'>

          {enableSearch && <div className='flex items-center justify-start p-1'>
            <Icon icon="akar-icons:search" width="1.3em" height="1.3em" style={{ color: '#98A2B3' }} />
          </div>}
        </div>


      );
    };

    const endContent = () => {
      return (
        <div className="absolute right-0 h-full flex items-end justify-end gap-1 bg-redd-300">
          {enableImage &&
            <div className="pr-2">
              <Dropdown>
                <DropdownTrigger aria-label="Upload Image" title='Upload Image'>
                  <Icon className="text-default-500 cursor-pointer" icon="solar:gallery-minimalistic-linear" width={20} height={30} />
                </DropdownTrigger>
                <DropdownMenu aria-label="Static Actions" onAction={handleDropdownAction}>
                  <DropdownItem key="ocr">Upload Image</DropdownItem>
                  <DropdownItem key="barcode">Upload Barcode</DropdownItem>
                  <DropdownItem key="cameraocr">Take Photo</DropdownItem>
                </DropdownMenu>
              </Dropdown>

              {/* Hidden input for file upload (Camera) */}
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                capture="environment" // Opens the camera by default
                style={{ display: "none" }}
                onChange={(e) => handleImageUpload?.(selectedUploadFileType?.current, e)}
              />
            </div>
          }
          {enableTranslation && enableSpeak && <SearchableDropdown selectedKey={selectedKey} className='max-h-[200px] overflow-y-auto' onSelectionChange={(key) => handleDropDown(key)} availableLanguages={SpeechUtility.translateLanguages} />}
          {enableSpeak &&
            <CtyButton id={ID_CONSTANTS.chatSpeakBtn} isIconOnly tooltipContent="Speak" radius="full" size="sm" variant="light" onPress={handleSpeechAction}>
              <div className="relative">
                {isListening && <Spinner className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />}
                <Icon className="text-default-500" icon={isListening ? "proicons:soundwave" : "proicons:microphone-off"} width={isListening ? 22 : 20} style={isListening ? { color: "#b51a1a" } : undefined} />
              </div>
            </CtyButton>
          }
          {enableSendBtn && <CtyButton
            tooltipContent="Send message"
            id='chatsend-btn'
            isIconOnly
            isDisabled={isSocketLoading || !inputValue}
            radius="lg"
            size="sm"
            variant="light"
          >
            <Icon
              onClick={handleSubmit}
              className={cn(
                "[&>path]:stroke-[2px]",
                !inputValue ? "text-default-600" : "text-primary-foreground ",
              )}
              icon="icon-park-outline:send-one"
              width={20}
              color="DD3399"
            />
          </CtyButton>}
        </div>
      );
    };

    return (
      <>

        {elementType === promptInputType.TEXTAREA &&
          <Textarea
            ref={ref}
            aria-label="Prompt"
            className={`${inputHeight ?? 'min-h-[40px]'} `}
            classNames={{
              ...classNames,
              label: cn("hidden", classNames?.label),
              input: cn("py-0", classNames?.input),
            }}
            minRows={1}
            value={inputValue}
            onValueChange={setInputValue}
            onChange={(e) => triggerEveryInputChange && handleSendClick(e.target.value)}
            onKeyDown={(e) => (e.key === 'Enter' && !e.shiftKey) && handleSubmit(e)}
            placeholder="Enter a prompt here"
            radius="none"
            endContent={endContent()}
            startContent={startContent()}
            variant="flat"
            disabled={isSocketLoading}
            autoFocus={true}
            {...props}
          />
        }
        {
          elementType === promptInputType.INPUT &&
          <Input
            aria-label="Prompt"
            size={props.size}
            type="text"
            endContent={endContent()}
            startContent={startContent()}
            placeholder={props.placeholder}
            id={props.id}
            onChange={(e) => triggerEveryInputChange && handleSendClick(e.target.value)}
            onKeyDown={(e) => (e.key === 'Enter' && !e.shiftKey) && handleSubmit(e)}
            value={inputValue}
            onValueChange={setInputValue}
            className={`min-w-sm ${inputHeight ?? 'min-h-[40px]'}`}
            disabled={isSocketLoading}
            autoFocus={true}
          />
        }
      </>
    );
  },
);

export default CtyPromptInput;
export { CtyPromptInput };

CtyPromptInput.displayName = "CtyPromptInput";
