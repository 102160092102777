import React, { useState } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input } from '@nextui-org/react';
import { ID_CONSTANTS } from '../utils/constants';
import { SpeechUtility } from '../utils/speechUtils';

interface SearchableDropdownProps {
  onSelectionChange: (key: string) => void;
  className?: string;
  selectedKey: string;
  availableLanguages?: { [key: string]: string };
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  className,
  onSelectionChange,
  selectedKey,
  availableLanguages = SpeechUtility.languages
}) => {

  const [searchTerm, setSearchTerm] = useState('');
  const languageKeys = Object.entries(availableLanguages);

  const filteredItems = languageKeys.filter(([key, value]) => value.toLowerCase().includes(searchTerm.toLowerCase()));

  const selectedValue = React.useMemo(() => SpeechUtility.languages[selectedKey], [selectedKey]);
  return (
    <Dropdown className={className}>
      <DropdownTrigger>
        <Button
          id={ID_CONSTANTS.chatSpeechLanguageBtn + selectedKey}
          size='sm'
          variant="flat"
          className="capitalize"
        >
          {selectedValue}
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Single selection example"
        variant="flat"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selectedKey}
        onSelectionChange={(selkey) => {
          const key: string = Array.from(selkey)[0].toString();
          onSelectionChange(key);
          SpeechUtility.setLanguage(key);
        }}
        items={filteredItems}
        defaultSelectedKeys={[selectedKey]}
        className='w-[200px] overflow-y-auto'
      >
        <DropdownItem key="search" isReadOnly>
          <Input
            placeholder="Search language..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </DropdownItem >
        <>
          {filteredItems.map(([key, value]) => {
            return <DropdownItem key={key}>{value}</DropdownItem>;
          })}
        </>
      </DropdownMenu>
    </Dropdown>
  );
};

export { SearchableDropdown };