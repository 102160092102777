import React, { useState } from "react";
import { Card, Button, Spinner, Progress } from "@nextui-org/react";
import { Icon } from "@iconify/react";

interface FileUploadProps {
  /** Function to handle uploaded files */
  onFileUpload: (files: File[]) => Promise<void>; // Update to return a Promise
  /** Function to validate files, return true if valid */
  validateFile?: (file: File) => boolean;
  /** Placeholder text for drag-and-drop area */
  dropzonePlaceholder?: string;
  /** Text for the upload button */
  uploadButtonText?: string;
  /** Optional additional handler for errors */
  onError?: (error: string) => void;
  fileInputRef?: React.RefObject<HTMLInputElement>;
  setTranscribeData?: any;
  handleRemoveFile?: any;
  loadingText?: string; // New prop for loading text
  loadingProgress?: number;
}

const CommonFileUpload: React.FC<FileUploadProps> = ({
  onFileUpload,
  validateFile,
  dropzonePlaceholder = "Drag and drop files here or click to upload",
  uploadButtonText = "Upload Files",
  onError,
  fileInputRef,
  setTranscribeData,
  handleRemoveFile,
  loadingText = "Uploading...", // New prop for loading text
  loadingProgress
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false); // New state for uploading

  const handleFileUpload = async (files: FileList | null) => {
    if (!files) return;
    setTranscribeData([]);
    const validFiles: File[] = [];
    for (const file of Array.from(files)) {
      if (validateFile && !validateFile(file)) {
        onError?.(`Invalid file: ${file.name}`);
        continue;
      }
      validFiles.push(file);
    }

    if (validFiles.length > 0) {
      setIsUploading(true); // Set uploading state to true
      setUploadedFiles(validFiles); // Replace existing files with new ones

      try {
        await onFileUpload(validFiles); // Wait for the API call to resolve
      } catch (error) {
        onError?.(`Upload failed: ${error instanceof Error ? error.message : "Unknown error"}`);
      } finally {
        setIsUploading(false); // Set uploading state to false
      }
    }
  };

  const handleRemoveFileFromUploader = (fileName: string) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    handleRemoveFile(fileName);

  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    handleFileUpload(e.dataTransfer.files);
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(e.target.files);
  };
  return (
    <Card className="w-full max-w-lg mx-auto">
      <Button
        className={`h-[200px] rounded-lg p-6 bg-[white] text-center cursor-pointer transition-colors ${isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
          }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => document.getElementById("file-input")?.click()}
        disabled={isUploading} // Disable button while uploading
      >
        {isUploading &&
          <Progress
            classNames={{
              base: "max-w-md",
              track: "drop-shadow-md border border-default",
              indicator: "bg-gradient-to-r from-blue-500 to-yellow-500",
              label: "tracking-wider font-medium text-default-600",
              value: "text-foreground/60",
            }}
            label={loadingText}
            radius="sm"
            showValueLabel={true}
            size="sm"
            value={loadingProgress}
          />
        }
        <div className="flex flex-col items-center justify-center gap-4">
          {isUploading ? (
            <Progress aria-label="Loading..." size="sm" value={30} /> // Show spinner while uploading
          ) : (
            <>
              <div className="p-2 outline-dotted outline-[#D0D5DD] rounded-lg">
                <Icon icon="icon-park-outline:upload-one" width="22" height="22" style={{ color: 'black' }} />
              </div>
              <p>{dropzonePlaceholder}</p>
            </>
          )}
        </div>
        <input
          ref={fileInputRef}
          id="file-input"
          type="file"
          multiple
          className="hidden"
          onChange={handleFileInput}
          disabled={isUploading} // Disable file input while uploading
        />
      </Button>

      {/* Display uploaded files */}
      {uploadedFiles.length > 0 && (
        <div className="mt-4">
          {uploadedFiles.map((file) => (
            <div key={file?.name} className="flex items-center justify-between px-3 font-semibold border-b border-gray-200 ">
              <span>{file?.name ?? ''}</span>
              <Button
                onPress={() => handleRemoveFileFromUploader(file.name)}
                disabled={isUploading} // Disable remove button while uploading
                className="bg-[transparent]"
              >
                <Icon icon="icon-park-outline:delete" width="16" height="16" style={{ color: 'red' }} />
              </Button>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};

export { CommonFileUpload };