import { createSlice } from '@reduxjs/toolkit';

export const GLBOAL_CART_SLICE_FEATURE_KEY = 'globalCartSlice';

export interface PartDetailsState {
    componentName: string;
    referenceNumber: string;
    partNumber: string;
    partName: string;
    quantity: string;
}

export interface ItemDetailsState {
    quantity: number;
    inCart: boolean;
    rowId: string;
    partDetails: PartDetailsState;
}

export interface GlobalCartSliceState {
    itemsList: Record<string, ItemDetailsState>;
}

export const initialGlobalCartSliceState: GlobalCartSliceState = {
    itemsList: {}
};

export const globalCartSliceSlice = createSlice({
    name: GLBOAL_CART_SLICE_FEATURE_KEY,
    initialState: initialGlobalCartSliceState,
    reducers: {
        addItemToCart(state, action) {
            const { rowId, inCart, quantity, partDetails } = action.payload;
            state.itemsList[rowId] = {
                quantity,
                inCart,
                rowId,
                partDetails: partDetails ?? state.itemsList[rowId]?.partDetails
            };
        }, 

        updateQuantity(state, action) {
            const { rowId, quantity } = action.payload;
            state.itemsList[rowId].quantity = quantity;
        },

        deleteItem(state, action) {
            const { rowId } = action.payload;
            delete state.itemsList[rowId];
        }
    }
});

export const globalCartSliceReducer = globalCartSliceSlice.reducer;
export const { addItemToCart, updateQuantity, deleteItem } = globalCartSliceSlice.actions;
