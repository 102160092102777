import { useEffect, useState } from "react";
import { useGetAgentsJSONMutation, useGetPDFUploadPresignedUrlMutation, useUploadFileToS3Mutation } from '@circuitry-ai/doc-data';
import { checkType, CtyButton, CtyShowToast, CtySpinner, JsonFormViewer, PDFViewer } from "@circuitry-ai/doc-ui";
import { Icon } from "@iconify/react";
import { Input, Select, SelectItem } from "@nextui-org/react";
// import { Upload, FileText, X, AlertCircle } from 'lucide-react';

export interface PdfScannerProps { }

export function PdfScanner(props: PdfScannerProps) {

  const [pdfScannerInfo, setPdfScannerInfo] = useState({
    documentType: 'invoice',
    pdfFile: null as File | null,
    pdfURL: "",
    invoiceData: null as object | null,
    loading: false
  });

  const [getPresignedUrl] = useGetPDFUploadPresignedUrlMutation();
  const [uploadtos3] = useUploadFileToS3Mutation();
  const [getAgentsJSON] = useGetAgentsJSONMutation();
  const [pdfScannerError, setPdfScannerError] = useState<any>(null);


  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setPdfScannerInfo(prev => ({ ...prev, loading: true, invoiceData: null, pdfFile: file }));
    setPdfScannerError(null);
    try {
      const payLoad = {
        agent_id: import.meta.env.CTY_AGENT_ID,
        data: [{
          document_type: pdfScannerInfo.documentType,
          filename: file.name,
          parts: 0,
          file_type: file.type.split('/')[1]
        }]
      };

      const uploadURL: any = await getPresignedUrl(payLoad).unwrap();
      await uploadtos3({
        file,
        presignedUrl: uploadURL.presigned_urls.part_1,
        CtyShowToast
      }).unwrap();

      // Allow time for processing
      await new Promise((resolve) => setTimeout(resolve, 2000));

      const invJSON = await getAgentsJSON({ source_id: uploadURL.source_id }).unwrap();
      setPdfScannerInfo(prev => ({ ...prev, invoiceData: invJSON }));
    } catch (error) {
      CtyShowToast('error', error?.message || 'Failed to process PDF');
      setPdfScannerError(error);
    } finally {
      setPdfScannerInfo(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (pdfScannerInfo?.pdfFile) {
      const url = URL.createObjectURL(pdfScannerInfo?.pdfFile);
      setPdfScannerInfo(prev => ({ ...prev, pdfURL: url }));

      return () => URL.revokeObjectURL(url);
    }
  }, [pdfScannerInfo?.pdfFile]);

  return (
    <div className="flex flex-col sm:w-[100%] md:w-auto lg:w-auto xl:w-auto bg-gray-50 h-full overflow-y-auto p-3 gap-3 lg:flex-row min-h-screen mb-4">
      {/* PDF Viewer Section */}
      <div className="flex-1 lg:w-1/2 overflow-y-auto">
        <div className="bg-white rounded-lg shadow-sm p-4 lg:p-6 mb-4">
          <h1 className="text-xl lg:text-2xl font-bold text-gray-800 mb-4 lg:mb-6">PDF Scanner</h1>

          <div className="flex flex-col lg:flex-row gap-4 mb-4 lg:mb-6">
            <div className="w-full lg:flex-1 lg:min-w-[140px]">
              <Select
                label="Document Type"
                placeholder="Select Document Type"
                className="max-w-xs"
                onChange={(e) => setPdfScannerInfo(prev => ({ ...prev, documentType: e.target.value }))}
                defaultSelectedKeys={[pdfScannerInfo?.documentType]}
              >
                <SelectItem value="invoice" key={'invoice'}>Invoice</SelectItem>
                <SelectItem value="work_order" key={'work_order'}>Work Order</SelectItem>
              </Select>
            </div>

            <div className="w-full lg:flex-1 lg:self-end lg:w-[50%]">
              {!pdfScannerInfo?.pdfFile ? (
                <label className="flex flex-col items-center justify-center w-full p-6 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 hover:bg-blue-50 transition-colors">
                  <div className="flex flex-col items-center">
                    <Icon icon="material-symbols:upload" />
                    <p className="text-sm text-gray-600">
                      <span className="font-semibold">Upload PDF</span>
                    </p>
                    <p className="text-xs text-gray-500">PDF files only</p>
                  </div>
                  <Input
                    type="file"
                    accept="application/pdf"
                    onChange={handleFileUpload}
                    className="hidden"
                  />
                </label>
              ) : (
                <div className="flex items-center gap-3 p-3 bg-blue-50 rounded-lg">
                  <span className="flex-1 text-sm text-blue-700 truncate" title={pdfScannerInfo?.pdfFile.name}>
                    {pdfScannerInfo?.pdfFile.name?.length > 50
                      ? `${pdfScannerInfo.pdfFile.name.slice(0, 50)}...`
                      : pdfScannerInfo?.pdfFile.name}
                  </span>
                  <CtyButton
                    onPress={() => setPdfScannerInfo(prev => ({
                      ...prev,
                      pdfFile: null,
                      pdfURL: "",
                      invoiceData: null
                    }))}
                    className="p-1 hover:bg-blue-100 rounded-full transition-colors min-w-0"
                    variant='flat'
                    color="white"
                  >
                    <Icon icon="radix-icons:cross-2" />
                  </CtyButton>
                </div>
              )}
            </div>
          </div>

          {pdfScannerInfo?.pdfFile && (
            <div className="sm:min-h-[400px] bg-gray-50 rounded-lg overflow-hidden lg:h-[70vh]">
              <PDFViewer fileUrl={pdfScannerInfo?.pdfURL} />
            </div>
          )}
        </div>
      </div>

      {/* Data Display Section */}
      <div className="flex-1 p-4 mb-16 lg:w-1/2 overflow-y-auto border-t lg:border-l lg:border-t-0 border-gray-200">
        {pdfScannerError && pdfScannerError.message ? <div>
          <h2 className="text-lg p-2 lg:text-xl font-semibold text-gray-800 lg:mb-6">Error</h2>
          <div className="rounded-lg shadow-sm bg-red-50 p-4 text-red-800">
            <p className="text-sm lg:text-base">{pdfScannerError.message}</p>
          </div>
        </div> : <div className="rounded-lg shadow-sm relative bg-white">
          <h2 className="text-lg p-2 lg:text-xl font-semibold text-gray-800 lg:mb-6">PDF Data</h2>

          {pdfScannerInfo?.loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-50">
              <CtySpinner size="lg" />
            </div>
          )}

          <div className="overflow-y-auto">
            {pdfScannerInfo?.invoiceData ? (
              <JsonFormViewer jsonData={pdfScannerInfo?.invoiceData} />
            ) : !pdfScannerInfo?.loading && pdfScannerInfo?.pdfFile ? (
              <div className="flex flex-col items-center justify-center py-8 text-gray-500">
                <p className="text-sm lg:text-base">Processing PDF data...</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center py-8 text-gray-500">
                <Icon icon="material-symbols:upload" />
                <p className="text-sm lg:text-base">Upload a PDF to view extracted data</p>
              </div>
            )}
          </div>
        </div>}
      </div>
    </div>
  );
}

export default PdfScanner;