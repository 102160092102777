import { AbilityBuilder, PureAbility, AbilityClass } from '@casl/ability';
import { createContext } from 'react';

type Actions = 'manage' | 'create' | 'edit' | 'test' | 'deploy' | 'delete' | 'view' | 'upload' | 'update' | 'unlink' | 'add' | 'change' | 'addNew' | 'activate' | 'deactivate' | 'invitepaluser' | 'trainurl' | 'expertansweredit' | 'expertanswerview' | 'explainanswer' | 'addnewusers' | 'analytics' | 'publisharticle' | 'ssowrite' | 'ssoread' | 'automations' | 'pdfscanner' | 'ocrscanner' | 'list' | "viewhistory" | "changeusersroles" | 'prompttuning';
type Subjects = 'all' | 'Advisors' | 'Knowledge' | 'Conversations' | 'Settings' | 'Home' | 'Profile' | 'Dashboard' | 'Agent';
type SubModules = 'Converse' | 'Metadata' | 'History' | 'UserManagement' | 'ProfileManagement';

export type AppAbility = PureAbility<[Actions, Subjects | SubModules]>;
export const AbilityContext = createContext<AppAbility>(undefined!);

export type User = { role: string, permissions__C: string[] };

export function defineRulesFor(role: string, permissions: string[]) {
  const { can, build } = new AbilityBuilder<AppAbility>(PureAbility as AbilityClass<AppAbility>);
  permissions?.forEach(permission => {
    const [action, subject] = permission.split(':');

    // Normalize the subject to match the expected Subject type
    const normalizedSubject: Subjects | SubModules = subject.charAt(0).toUpperCase() + subject.slice(1).toLowerCase() as Subjects | SubModules;
    switch (normalizedSubject) {
      case 'Advisors':
      case 'Knowledge':
      case 'Conversations':
      case 'Settings':
      case 'Dashboard':
      case 'Home':
      case 'Agent':
        can(action as Actions, normalizedSubject as Subjects);
        break;
      default:
        // Handle unexpected subjects or log a warning
        console.warn(`Unexpected subject: ${subject}`);
        break;
    }
  });
  return build();
}

export function buildAbility(user: any) {
  return defineRulesFor(user.role, user.permissions__C);
}