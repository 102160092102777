import {
  Button,
  Tooltip
} from "@nextui-org/react";
import type { PressEvent } from '@react-types/shared';

/* eslint-disable-next-line */
import React, { forwardRef } from 'react';

export interface ButtonProps {
  // Props for content
  title?: string; // The title of the button
  id?: string; // The id of the button
  children: React.ReactNode; // The content to display in the button
  startContent?: React.ReactNode; // The content at the start of the button
  endContent?: React.ReactNode; // The content at the end of the button

  // Props for styles and variants
  variant?: 'solid' | 'bordered' | 'light' | 'flat' | 'faded' | 'shadow' | 'ghost'; // The button appearance style
  color?: 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'white'; // The button color theme
  size?: 'sm' | 'md' | 'lg'; // The button size
  radius?: 'none' | 'sm' | 'md' | 'lg' | 'full'; // The button border radius
  fullWidth?: boolean; // Whether the button should take the full width of its parent
  isIconOnly?: boolean; // Whether the button should have the same width and height
  isDisabled?: boolean; // Whether the button is disabled
  className?: string | undefined; // Additional classes to apply to the button

  // Props for loading state
  isLoading?: boolean; // Whether the button is loading
  spinner?: React.ReactNode; // Spinner to display when loading
  spinnerPlacement?: 'start' | 'end'; // The spinner placement
  tooltipContent?: string; // The tooltip text to display when hovering over the button
  toolTipPosition?: 'top' | 'right' | 'bottom' | 'left'; // The tooltip position
  toolTipColor?: 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'danger'; // The tooltip color

  // Props for button interactions and effects
  disableRipple?: boolean; // Whether the button should display a ripple effect on press
  disableAnimation?: boolean; // Whether the button should display animations

  // Event Handlers
  onPress?: (e: PressEvent) => void; // Handler called when the press is released over the target
  onPressStart?: (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => void; // Handler called when a press interaction starts
  onPressEnd?: (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => void; // Handler called when a press interaction ends
  onPressChange?: (isPressed: boolean) => void; // Handler called when the press state changes
  onPressUp?: (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => void; // Handler called when a press is released
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>; // Handler called when a key is pressed
  onKeyUp?: React.KeyboardEventHandler<HTMLButtonElement>; // Handler called when a key is released
  onClick?: React.MouseEventHandler<HTMLButtonElement>; // Deprecated: use onPress instead
}




const CtyButton = forwardRef<HTMLButtonElement, ButtonProps>(({
  title,
  id,
  children,
  startContent,
  endContent,
  variant,
  className,
  color,
  size,
  radius,
  fullWidth = false,
  isIconOnly = false,
  isDisabled = false,
  isLoading = false,
  tooltipContent = '',
  toolTipColor = 'default',
  toolTipPosition = 'top',
  spinner,
  spinnerPlacement = 'start',
  disableRipple = false,
  disableAnimation = false,
  onPress,
  onClick
}, ref) => {
  const buttonContent = (
    <Button
      isDisabled={isDisabled || isLoading}
      ref={ref}
      onPress={onPress}
      onClick={onClick}
      variant={variant}
      color={color}
      size={size}
      radius={radius}
      fullWidth={fullWidth}
      isIconOnly={isIconOnly}
      className={`font-semibold text-sm h-[36px] ${className}`}
      isLoading={isLoading}
      id={id}
      disableRipple={disableRipple}
      disableAnimation={disableAnimation}
      title={title}
    
    >
      {isLoading && spinnerPlacement === 'start' && spinner}
      {startContent && !isLoading && <span className="start-content">{startContent}</span>}
      {children}
      {endContent && !isLoading && <span className="end-content">{endContent}</span>}
      {isLoading && spinnerPlacement === 'end' && spinner}
    </Button>
  );

  return tooltipContent ? (
    <Tooltip content={tooltipContent} placement={toolTipPosition} showArrow color={toolTipColor}>
      {buttonContent}
    </Tooltip>
  ) : buttonContent;
});

export { CtyButton };
