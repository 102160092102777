
import { RootState } from '@circuitry-ai/doc-data';
import { useSelector } from 'react-redux';

/* eslint-disable-next-line */
export interface EmbedZapierProps { }

export function EmbedZapier(props: EmbedZapierProps) {
  const { details: user } = useSelector((state: RootState) => state.auth.user);

  return (
    <div className='p-2 h-[calc(100vh-80px)] overflow-y-auto'>
      <zapier-workflow
        sign-up-email={`${user?.email}`}
        sign-up-first-name={`${user?.given_name ?? user?.nickname}`}
        sign-up-last-name={`${user?.family_name}`}
        client-id="W19G3T7q6ALwoKEF6h3q9MmOp4DD5Oh6vgm8x6S2"
        theme="auto"
        intro-copy-display="show"
        guess-zap-display="show"
      />
    </div>
  );
}

export default EmbedZapier;
